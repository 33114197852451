import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function ProjectShow() {
    let params = useParams();
    const { t } = useTranslation();
    const [project, setProject] = useState(null);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("projects/" + params.id, "GET", []).then((res) => {
            setProject(res.data.project);
        });
    }, [refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("employees", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/hr/employees' /> :
        <>
            <div className="container-fluid mt-4">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-sm-12">
                        <div className="nav-wrapper position-relative end-0">
                            <ul className="nav nav-pills nav-fill bg-white blur shadow-blur p-1" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link mb-0 px-0 py-1 active  active " data-bs-toggle="tab" href="../../../examples/pages/account/settings.html" role="tab" aria-selected="true">
                                        Overview
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="../../../examples/pages/account/billing.html" role="tab" aria-selected="false">
                                        Reporting
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="../../../examples/pages/account/invoice.html" role="tab" aria-selected="false">
                                        Members
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="../../../examples/pages/account/security.html" role="tab" aria-selected="false">
                                        Files
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="../../../examples/pages/account/security.html" role="tab" aria-selected="false">
                                        Notes
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="../../../examples/pages/account/security.html" role="tab" aria-selected="false">
                                        Invoices
                                    </a>
                                </li>
                                <div className="moving-tab position-absolute nav-link" style={{ padding: 0, transition: 'all 0.5s ease 0s', transform: 'translate3d(0px, 0px, 0px)', width: 81 }}><a className="nav-link mb-0 px-0 py-1 active  active " data-bs-toggle="tab" href="../../../examples/pages/account/settings.html" role="tab" aria-selected="true">-</a></div></ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid my-3 py-3">
                <div className="row mb-5">
                    <div className="col-lg-9 mt-lg-0">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                                <div className="card">
                                    <div className="card-body p-3">
                                        <div className="d-flex">
                                            <div>
                                                <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                    <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <div className="numbers">
                                                    <p className="text-sm mb-0 text-capitalize font-weight-bold">Project Budget</p>
                                                    <h5 className="font-weight-bolder mb-0">
                                                        ${project && project.quotation.total_gross}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                                <div className="card">
                                    <div className="card-body p-3">
                                        <div className="d-flex">
                                            <div>
                                                <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                    <i className="ni ni-world text-lg opacity-10" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <div className="numbers">
                                                    <p className="text-sm mb-0 text-capitalize font-weight-bold">Quation Number</p>
                                                    <h5 className="font-weight-bolder mb-0">
                                                        <u>{project && project.quotation.identity_number}</u>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                                <div className="card">
                                    <div className="card-body p-3">
                                        <div className="d-flex">
                                            <div>
                                                <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                                    <i className="ni ni-planet text-lg opacity-10" aria-hidden="true" />
                                                </div>
                                            </div>
                                            <div className="ms-3">
                                                <div className="numbers">
                                                    <p className="text-sm mb-0 text-capitalize font-weight-bold">Project Owner</p>
                                                    <h5 className="font-weight-bolder mb-0">
                                                        <u>{project && project.quotation.customer.name}</u>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-body mt-4" id="profile">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-sm-auto col-4">
                                    <div className="avatar avatar-xl position-relative">
                                        <img src="../../../assets/img/bruce-mars.jpg" alt="bruce" className="w-100 border-radius-lg shadow-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-auto col-8 my-auto">
                                    <div className="h-100">
                                        <h5 className="mb-1 font-weight-bolder">
                                            Client Name
                                        </h5>
                                        <p className="mb-0 font-weight-bold text-sm">
                                            CEO / Co-Founder
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4" id="basic-info">
                            <div className="card-body pt-0">
                                <div className="card-header pb-0 p-3">
                                    <div className="row">
                                        <div className="col-md-8 d-flex align-items-center">
                                            <h6 className="mb-0">{t('Customer Information')}</h6>
                                        </div>
                                        <div className="col-md-4 text-end">
                                            <a href="#0">
                                                <i className="fas fa-user-edit text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" aria-hidden="true" aria-label="Edit Profile" /><span className="sr-only">Edit Profile</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body p-3">
                                    <ul className="list-group">
                                        <li className="list-group-item border-0 ps-0 pt-0 text-sm"><strong className="text-dark">Full Name:</strong> &nbsp; Alec M. Thompson</li>
                                        <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Mobile:</strong> &nbsp; (44) 123 1234 123</li>
                                        <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Email:</strong> &nbsp; alecthompson@mail.com</li>
                                        <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Location:</strong> &nbsp; USA</li>
                                        <li className="list-group-item border-0 ps-0 pb-0">
                                            <strong className="text-dark text-sm">Social:</strong> &nbsp;
                                            <a className="btn btn-facebook btn-simple mb-0 ps-1 pe-2 py-0" href="#0">
                                                <i className="fab fa-facebook fa-lg" aria-hidden="true" />
                                            </a>
                                            <a className="btn btn-twitter btn-simple mb-0 ps-1 pe-2 py-0" href="#0">
                                                <i className="fab fa-twitter fa-lg" aria-hidden="true" />
                                            </a>
                                            <a className="btn btn-instagram btn-simple mb-0 ps-1 pe-2 py-0" href="#0">
                                                <i className="fab fa-instagram fa-lg" aria-hidden="true" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-4" id="delete">
                            <div className="card-header">
                                <h5>Delete Account</h5>
                                <p className="text-sm mb-0">Once you delete your account, there is no going back. Please be certain.</p>
                            </div>
                            <div className="card-body d-sm-flex pt-0">
                                <div className="d-flex align-items-center mb-sm-0 mb-4">
                                    <div>
                                        <div className="form-check form-switch mb-0">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault0" />
                                        </div>
                                    </div>
                                    <div className="ms-2">
                                        <span className="text-dark font-weight-bold d-block text-sm">Confirm</span>
                                        <span className="text-xs d-block">I want to delete my account.</span>
                                    </div>
                                </div>
                                <button className="btn btn-outline-secondary mb-0 ms-auto" type="button" name="button">Deactivate</button>
                                <button className="btn bg-gradient-danger mb-0 ms-2" type="button" name="button">Delete Account</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <h6>Timeline</h6>
                                <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-bell-55 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">$2400, Design changes</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">22 DEC 7:20 PM</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-success">Design</span>
                                        </div>
                                    </div>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-html5 text-danger text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">New order #1832412</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">21 DEC 11 PM</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-danger">Order</span>
                                            <span className="badge badge-sm bg-gradient-danger">#1832412</span>
                                        </div>
                                    </div>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-cart text-info text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">Server payments for April</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">21 DEC 9:34 PM</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-info">Server</span>
                                            <span className="badge badge-sm bg-gradient-info">Payments</span>
                                        </div>
                                    </div>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-credit-card text-warning text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">New card added for order #4395133</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">20 DEC 2:20 AM</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-warning">Card</span>
                                            <span className="badge badge-sm bg-gradient-warning">#4395133</span>
                                            <span className="badge badge-sm bg-gradient-warning">Priority</span>
                                        </div>
                                    </div>
                                    <div className="timeline-block mb-3">
                                        <span className="timeline-step">
                                            <i className="ni ni-key-25 text-primary text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">Unlock packages for development</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">18 DEC 4:54 AM</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-primary">Development</span>
                                        </div>
                                    </div>
                                    <div className="timeline-block">
                                        <span className="timeline-step">
                                            <i className="ni ni-archive-2 text-success text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">New message unread</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">16 DEC</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-success">Message</span>
                                        </div>
                                    </div>
                                    <div className="timeline-block">
                                        <span className="timeline-step">
                                            <i className="ni ni-check-bold text-info text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">Notifications unread</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">15 DEC</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="timeline-block">
                                        <span className="timeline-step">
                                            <i className="ni ni-box-2 text-warning text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">New request</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">14 DEC</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-warning">Request</span>
                                            <span className="badge badge-sm bg-gradient-warning">Priority</span>
                                        </div>
                                    </div>
                                    <div className="timeline-block">
                                        <span className="timeline-step">
                                            <i className="ni ni-controller text-dark text-gradient" />
                                        </span>
                                        <div className="timeline-content">
                                            <h6 className="text-dark text-sm font-weight-bold mb-0">Controller issues</h6>
                                            <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">13 DEC</p>
                                            <p className="text-sm mt-3 mb-2">
                                                People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.
                                            </p>
                                            <span className="badge badge-sm bg-gradient-dark">Controller</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-9">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Select Checklist & Form</label>
                                    <select className="form-control">
                                        <option>Form 1</option>
                                        <option>Form 2</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
}

export default ProjectShow;
