import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Select from 'react-select';
import SoftDropzone from '../../../components/SoftDropzone';

import Timeline from "./Timeline";

function CallCenter(props) {
    const { t } = useTranslation();
    const submitBtn = useRef();
    const [data, setData] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [create, setCreate] = useState(true);
    const [formdata, setFormdata] = useState({});

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        flatpickr(".flat-pickr");

        callFetch("projects/create", "GET", []).then((res) => {
            setData(res.data);
        });
        
        if(props.update && props.update == true){
            setCreate(false);

            callFetch("projects/" + props.projectid + "/edit", "GET", []).then((res) => {
                for (let [key, value] of Object.entries(res.data)) {
                    setValue(key, value);
                }
            });
        }
    }, [refresh]);

    function submitForm(e) {
        submitBtn.current && submitBtn.current.click();
    }

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch(create == true ? "projects" : "projects/"+ props.projectid, "POST", formData, setError).then((res) => {
            setSaving(false);
            setRefresh2(refresh2 + 1);

            if(res.project_id){
                setProjectId(res.project_id);
            }
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    useEffect(() => {
        setFormdata(getValues());
    }, [getValues()]);

    return submitSuccess && create ? <Navigate to={'/project-management/project/edit/'+projectId} /> :
        <>
        <div className="row mb-5">
            <div className="col-lg-9 mt-lg-0">
                <div className="row">
                    <div className="col-md-12">
                        <div className="emb-4">
                                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    {create == false ? (
                                        <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                    ): <></>}
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="form-group">
                                                        <label for="example-date-input" class="form-control-label">{t('Date')}</label>
                                                        
                                                        <input class="form-control" className="form-control"
                                                            {...register("date", {
                                                                required: true,
                                                            })}
                                                            required
                                                            type="date" id="example-date-input" />
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label>
                                                            {t('Time')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("time", {
                                                                required: true,
                                                            })}
                                                            required
                                                        >
                                                            <option value="08:00">08:00 - 10:00</option>
                                                            <option value="10:00">10:00 - 12:00</option>
                                                            <option value="12:00">12:00 - 14:00</option>
                                                            <option value="14:00">14:00 - 16:00</option>
                                                            <option value="16:00">16:00 - 18:00</option>
                                                            <option value="18:00">18:00 - 20:00</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            {t('Verkaufer')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("verkaufer", {
                                                                required: true,
                                                            })}
                                                            required
                                                        >
                                                            <option value="">--</option>
                                                            {data && data.employees ? data.employees.map((employee) => (
                                                                <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label>
                                                            {t('Beschreibung')}
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            placeholder={t('eg. 1234 My street, City')}
                                                            {...register("description")}></textarea>
                                                        <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label>{t('Anrede')} *</label>
                                                        <select
                                                            className="form-control"
                                                            {...register("anrede", {
                                                                required: true,
                                                            })}
                                                            required>
                                                            <option value="">--</option>
                                                            <option value="Herr">{t('Herr')}</option>
                                                            <option value="Frau">{t('Frau')}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Vorname')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("vorname", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                                            </div>                                            
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Name')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("name", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                            </div>                                            
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label>{t('Straße')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("street", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>{t('Nr')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("nr", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                                            </div>                                            
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label>{t('PLZ')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("plz", {
                                                                    required: true,
                                                                })}
                                                                required
                                                            />
                                                            <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <label>{t('Ort')} *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('')}
                                                                {...register("ort", {
                                                                    required: true,
                                                                })}
                                                                required
                                                            />
                                                            <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <div className="form-group">
                                                                <label>{t('Telefonnummer')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("telefonnummer", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="row g-3">
                                        <div className="col-12 my-4">
                                            {!saving && (
                                                <button type="submit" className="btn btn-primary" id="callSubmitBtn">
                                                    {t('Save')}
                                                </button>
                                            )}
                                            {saving && (
                                                <button type="submit" className="btn btn-disabled" disabled>
                                                    {t('Saving ...')}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
            <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr')}:</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {props.projectid ? props.projectid : '-'}                                               
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Timeline projectdata={formdata} refresh={refresh2} />
            </div>
        </div>
    </>;
}

export default CallCenter;