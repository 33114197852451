import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

function IndexTable() {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [invoiceId, setInvoiceId] = useState(0);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const createInvoice = (order) => {
        setSaving(true);
        callFetch("invoices", "POST", {
            auftrag_id: order.id,
            currency: order.currency,
            netto: order.netto,
            tax: order.tax,
            brutto: order.brutto,
            product_details: JSON.stringify({ items: [{ id: order.quation.speicher, title: '', quantity: 1, price: order.netto, currentPrice: order.netto, total: order.brutto }], emails: [], subTotal: order.netto, discountPercentage: 0, discount: 0, taxPercentage: 19, tax: 0, total: order.brutto }),
        }, setError).then((res) => {
            setSaving(false);
            //if (!res.ok) return;
            if(res.message == 'success'){
                setInvoiceId(res.id);
                setSubmitSuccess(true);
            }
        });
    };

    const tableHeadings = [
        {
            name: t('Order Nr.'),
            selector: row => (
                <NavLink to={'/sales-distribution/order/'+row.id+'/edit'}>
                    {row.id}
                </NavLink>
            ),
            sortable: true,
        },
        {
            name: t('Verkaufer'),
            cell: row => row.user.name,
        },
        {
            name: t('Datum'),
            selector: row => dateFormat(row.order_date, "dd.mm.yyyy"),
        },
        {
            name: t('Amount'),
            cell: row => (
                <>
                    <NumericFormat 
                        value={Number(row.brutto)} 
                        displayType="text" 
                        thousandSeparator={"."} 
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                </>
            ),
        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {
                    Cookies.get('permissions').indexOf("order-update") !== -1 ||
                    Cookies.get('permissions').indexOf("order-delete") !== -1 ? (
                        <div className="text-center dropstart">
                        <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-ellipsis-v text-xs"></i>
                        </a>
                        <ul className="dropdown-menu">
                            {Cookies.get('permissions').indexOf("invoice-create") !== -1 ? (
                                <>
                                    <li>
                                        <a href='#' onClick={()=>createInvoice(row)} className="dropdown-item">
                                            {t('Create Invoice')}
                                        </a>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                </>
                            ) : <></>}

                            {Cookies.get('permissions').indexOf("order-update") !== -1 ? (
                                <>
                                    <li>
                                        <NavLink to={'/sales-distribution/order/' + row.id + '/edit'} className="dropdown-item">
                                            {t('Edit')}
                                        </NavLink>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                </>
                            ) : <></>}
                            {Cookies.get('permissions').indexOf("order-delete") !== -1 ? (
                                <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'order', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                            ) : <></>}
                        </ul>
                    </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("order?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return submitSuccess ? <Navigate to={'/finance/invoices/' + invoiceId + '/edit'} /> :
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        ;
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata.data}
        noDataComponent={t('There are no records to display')}
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default IndexTable;