import { Suspense, useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
//import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";


import 'react-toastify/dist/ReactToastify.css';
import ApplicationSettings from "./pages/ApplicationSettings";
import PrivateOutlet from "./components/PrivateOutlet";
import Signin from "./pages/Signin";
/*
import SmtpIndex from './pages/user_settings/smtp/SmtpIndex';
import SmtpCreate from './pages/user_settings/smtp/SmtpCreate';
import SmtpEdit from './pages/user_settings/smtp/SmtpEdit';
*/
import EmployeeCreate from './pages/hr/employee/EmployeeCreate';
import EmployeeIndex from './pages/hr/employee/EmployeeIndex';
import EmployeeIndexx from './pages/hr/employee/EmployeeIndexx';
import EmployeeEdit from './pages/hr/employee/EmployeeEdit';
/*
import FormCreate from './pages/form/FormCreate';
import FormIndex from './pages/form/FormIndex';
import FormEdit from './pages/form/FormEdit';
import FormBuilder from './pages/form/FormBuilder';
import FormShow from './pages/form/FormShow';
import CustomerIndex from './pages/customer_management/customer/CustomerIndex';
import CustomerCreate from './pages/customer_management/customer/CustomerCreate';
*/

import Dashboard from './pages/dashboard/Index'; 

import QuationIndex from './pages/quations/Index';
import QuationCreate from './pages/quations/Create';
import QuationEdit from './pages/quations/Edit';

import OrdersIndex from './pages/orders/Index';
import OrderEdit from './pages/orders/Edit';

import ProductCreate from './pages/product/Create';

import ModuleIndex from './pages/module/Index';
import ModuleCreate from './pages/module/Create';
import ModuleEdit from './pages/module/Edit';

import SpeicherIndex from './pages/speicher/Index';
import SpeicherCreate from './pages/speicher/Create';
import SpeicherEdit from './pages/speicher/Edit';

import ProjectIndex from './pages/project-management/ProjectIndex';
import ProjectCreate from './pages/project-management/Create';
import ProjectEdit from './pages/project-management/Edit';
import ProjectShow from './pages/project-management/ProjectShow';

import CallcenterCreate from './pages/callcenter/Create';
import CallcenterEdit from './pages/callcenter/Edit';
import CallcenterIndex from './pages/callcenter/Index';


import Settings from './pages/settings/Index';


export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    const configsButton = (
        <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
        <Icon fontSize="default" color="inherit">
            settings
        </Icon>
        </SoftBox>
    );

    return (
        <>
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={themeRTL}>
                <Routes>
                    <Route path="/" element={<Signin />} />
                    <Route path="/*" element={<PrivateOutlet />}>
                        <Route path="application-settings" element={<ApplicationSettings />} />

                        <Route path="dashboard" element={<Dashboard />} /> 
                        <Route path="profile/settings" element={<Settings />} /> 

                        <Route path="hr/employees" element={<EmployeeIndex />} />
                        <Route path="hr/employees/create" element={<EmployeeCreate />} />
                        <Route path="hr/employees/:id/edit" element={<EmployeeEdit />} />

                        <Route path="sales-distribution/quations" element={<QuationIndex />} />
                        <Route path="sales-distribution/quations/create" element={<QuationCreate />} />
                        <Route path="sales-distribution/quations/:id/edit" element={<QuationEdit />} />

                        <Route path="sales-distribution/orders" element={<OrdersIndex />} />
                        <Route path="sales-distribution/order/:id/edit" element={<OrderEdit />} />

                        <Route path="supply-chain/products/create" element={<ProductCreate />} />
                        <Route path="supply-chain/products/module" element={<ModuleIndex />} />
                        <Route path="supply-chain/products/module/create" element={<ModuleCreate />} />
                        <Route path="supply-chain/products/module/:id/edit" element={<ModuleEdit />} />
                        <Route path="supply-chain/products/speicher" element={<SpeicherIndex />} />
                        <Route path="supply-chain/products/speicher/create" element={<SpeicherCreate />} />
                        <Route path="supply-chain/products/speicher/:id/edit" element={<SpeicherEdit />} />

                        <Route path="project-management/projects" element={<ProjectIndex />} />
                        <Route path="project-management/project/create" element={<ProjectCreate />} />
                        <Route path="project-management/project/edit/:id" element={<ProjectEdit />} />
                        <Route path="project-management/project/:id" element={<ProjectShow />} />


                        <Route path="call-center/index" element={<CallcenterIndex />} />
                        <Route path="call-center/create" element={<CallcenterCreate />} />
                        <Route path="call-center/:id/edit" element={<CallcenterEdit />} />

                        {/*
                        <Route path="user-settings/smtp" element={<SmtpIndex />} />
                        <Route path="user-settings/smtp/create" element={<SmtpCreate />} />
                        <Route path="user-settings/smtp/:id/edit" element={<SmtpEdit />} />
                        */}
                    </Route>
                </Routes>
            </ThemeProvider>
        </CacheProvider>
    </>
    );
}
