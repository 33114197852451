import { React, useState } from "react";
import { useTranslation } from 'react-i18next';

import CallCenter from "./create/CallCenter";
import Cookies from 'js-cookie';

import NavMenu from "./Layouts/NavMenu";

function Create() {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('callcenter');

    const activeDataTab = (data) => {
        setActiveTab(data);
    }


    return(
        <>
            <div className="container-fluid my-3 py-3">
                <div className="mb-3">
                    <NavMenu savebtn={true} actionbtn={false} activeTab={activeDataTab} />
                </div>

                {activeTab == 'callcenter' ? (
                    <CallCenter />
                ) : <></>}
            </div>
        </>
    )
}

export default Create;
