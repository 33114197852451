import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import ProjectIndexTable from './ProjectIndexTable';

function ProjectIndex() {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                    {Cookies.get('permissions').indexOf("project-create") !== -1 ? (
                        <NavLink to="/project-management/project/create" className="btn btn-icon btn-primary">
                            {t('Add Project')}
                        </NavLink>
                    ) : <></>}
                    
                    {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename != 'Call Center' ? (
                        <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'project-export'} className="btn btn-outline-dark pull-right">
                            {t('Export CSV')}
                        </a>
                    ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Projects')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <ProjectIndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectIndex;
