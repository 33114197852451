import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate, useParams } from "react-router-dom";

import CallCenter from "./create/CallCenter";
import { callFetch } from "../../helpers/callFetch";

import NavMenu from "./Layouts/NavMenu";
import Cookies from 'js-cookie';

function Edit() {
    let params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('callcenter');
    const [project, setProjects] = useState('callcenter');

    const activeDataTab = (data) => {
        setActiveTab(data);
    }

    useEffect(() => {
        if(Cookies.get('permissions').indexOf("project_call_center-read") !== -1){
            setActiveTab('callcenter');
        }else if(Cookies.get('permissions').indexOf("project_distribution-read") !== -1){
            setActiveTab('distribution');
        }else if(Cookies.get('permissions').indexOf("order_project-read") !== -1){
            setActiveTab('auftrag');
        }else if(Cookies.get('permissions').indexOf("project_order_processing-read") !== -1){
            setActiveTab('order_processing');
        }else if(Cookies.get('permissions').indexOf("project_files-read") !== -1){
            setActiveTab('files');
        }else if(Cookies.get('permissions').indexOf("project_notes-read") !== -1){
            setActiveTab('notes');
        }else if(Cookies.get('permissions').indexOf("project_accounting-read") !== -1){
            setActiveTab('accounting');
        }

        callFetch("projects/" + params.id + "/edit", "GET", []).then((res) => {
            if(res.message == 'success'){
                setProjects(res);
            }else{
                console.log('notfound');
                return  navigate('/not-found');
            }
        });
    }, [params.id]);

    return(
        <>
            <div className="container-fluid my-3 py-3">
                
                <div className="mb-3">
                    <NavMenu project={project} activeTab={activeDataTab} projectid={params.id} orderId={project.orderId} quationId={project.quationId} />
                </div>
                
                {activeTab == 'callcenter' ? (
                    <CallCenter update={true} projectid={params.id} />
                ) : <></>}
            </div>
        </>
    )
}

export default Edit;
