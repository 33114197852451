import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
//import Message from '../pages/chat/Message';
import callFetch from "../helpers/callFetch";
import { t } from "i18next"; 
//import LastMessage from "pages/chat/LastMessage";
import Moment from "react-moment";
function TopNav() {
    const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
    const [customClass, setCustomClass] = useState('');
    const [participations, setParticipations] = useState([]);
    const [unreadMessage, setunreadMessage] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotification, setUnreadNotification] = useState(0);
    useEffect(() => {
        callFetch("chat-participation", "GET", []).then((res) => {
            // console.log(res.data);
            setParticipations(res.contacts);
        });

        callFetch("unread-messages", "GET", []).then((res) => {
            // console.log(res.unread_message);
            setunreadMessage(res.unread_message);
        });   

        callFetch("topnav-notifications", "GET", []).then((res) => {
            // console.log(res.data);
            setNotifications(res.data);
            setUnreadNotification(res.unread_notifications);
        }); 

    }, []);

    useEffect(() => {
        const interval = setInterval(() => { 
            callFetch("unread-messages", "GET", []).then((res) => {
                // console.log(res.unread_message);
                setunreadMessage(res.unread_message);
            });
            
            callFetch("topnav-notifications", "GET", []).then((res) => {
                // console.log(res.data);
                setNotifications(res.data);
                setUnreadNotification(res.unread_notifications);
            });
        }, 20000); 
        return () => clearInterval(interval);
      },[]);

    //   useEffect(() => {
    //     const interval = setInterval(() => { 
    //         callFetch("unread-messages", "GET", []).then((res) => {
    //             // console.log(res.unread_message);
    //             setunreadMessage(res.unread_message);
    //         });   
    //     }, 240000); 
    //     return () => clearInterval(interval);
    //   },[]);

    const handleResponsive = () => {
        setCustomClass('user-chat-show')
      }

    const seenMessage = () => {
        callFetch("seen-messages", "GET", []).then((res) => {
            // console.log(res.unread_message);
            setunreadMessage(res.unread_message);
        });

        callFetch("unread-messages", "GET", []).then((res) => {
            // console.log(res.unread_message);
            setunreadMessage(res.unread_message);
        });
    }
    
    const seenNotification = () => {
        callFetch("seen-notification", "GET", []).then((res) => { 
            setUnreadNotification(0);
        });
    }

    const lang = Cookies.get('lang') ? JSON.parse(Cookies.get('lang')) : { flag: 'de', lang: 'de', name: 'Deutsch' };
    const [activeLanguage, setActiveLanguage] = useState(lang);
    const { i18n } = useTranslation();

    function handleLanguageChange(e, l) {
        e.preventDefault();

       
        callFetch("lang/change/"+l.lang, "GET", []).then((res) => {
            console.log(res.message); 
        }); 

        setActiveLanguage(l);
        i18n.changeLanguage(l.lang);
        Cookies.set('lang', JSON.stringify(l));
    }

    function doSignout(e) {
        e.preventDefault();

        callFetch('signout', 'POST', [], null).then(res => {
            Cookies.remove('user');
            Cookies.remove('token');
        });
    }

    return (
        <nav className="navbar navbar-main navbar-expand-lg mt-4 top-1 px-0 mx-4 border-radius-xl position-sticky blur shadow-blur left-auto z-index-sticky" id="navbarBlur" navbar-scroll="true">
            <div className="container-fluid py-1 px-3">
                <div className="collapse navbar-collapse" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        {/* <div className="dropdown">
                            <a href="flags" className="m-0 nav-link text-body font-weight-bold px-0 dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className={"flag-icon flag-icon-" + activeLanguage.flag}></span>{activeLanguage.name}
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="#0" onClick={(e) => handleLanguageChange(e, { flag: 'us', lang: 'en', name: 'English' })}><span className="flag-icon flag-icon-us"></span>English</a></li>
                                <li><a className="dropdown-item" href="#0" onClick={(e) => handleLanguageChange(e, { flag: 'de', lang: 'de', name: 'Deutsch' })}><span className="flag-icon flag-icon-de"></span>Deutsch</a></li>
                            </ul>
                        </div> */}
                    </div>
                    
                    <ul className="navbar-nav justify-content-end">
                        <li className="nav-item d-xl-none mx-3 d-flex align-items-center" style={{position: 'absolute', left: '-12px', top: '14px'}}>
                            <a href="#0" className="nav-link text-body p-0" id="iconNavbarSidenav">
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                </div>
                            </a>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center" style={{marginRight: "8px"}}>
                            <a href="notification" className="nav-link text-body p-0 mt-1" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa-solid fa-envelope cursor-pointer" style={{fontSize: "20px"}}>{ unreadMessage > 0 && <sup className="badge badge-danger" style={{
                                    fontSize: '5px',padding: '3px',
                                    height: '10px',
                                    width: '10px',
                                    position: 'relative',
                                    top: '1px',
                                    left: '-5px',
                                    backgroundColor: 'red',
                                    color: '#fff',
                                }}>{unreadMessage}</sup>}</i>
                            </a>
                            <ul style={{overflowY: 'scroll', maxHeight: '300px'}} className="dropdown-menu border-0 shadow dropdown-menu-end  px-2 py-3 me-sm-n4 res-dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {participations ? participations.map((participation) => (
                                    <li className="mb-2" onClick={() => { handleResponsive(); seenMessage(); }}>
                                        <NavLink className="dropdown-item border-radius-md" to={'/chat/message/'+participation.id}>
                                            <div className="d-flex py-1">
                                                <div className="my-auto">
                                                    <img src={user.photo ? process.env.REACT_APP_STORAGE_URL + user.photo : '/assets/img/placeholder.png'} className="avatar avatar-sm  me-3" alt="avatar" />
                                                </div>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="text-sm font-weight-normal mb-1"><span className="font-weight-bold"></span> {participation.name}</h6>
                                                    <p className="text-xs text-secondary mb-0">
                                                        {/* <i className="fa fa-clock me-1" /> */}
                                                         {/*<LastMessage userId={participation.id}></LastMessage>*/}
                                                    </p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                )) : <></>}
                            </ul>
                        </li>

                        <li className="nav-item dropdown pe-2 d-flex align-items-center" style={{marginRight: "8px"}}>
                            <a href="notification" className="nav-link text-body p-0 mt-1" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-bell cursor-pointer" style={{fontSize: "20px"}}>
                                    { unreadNotification > 0 && <sup className="badge badge-danger" style={{
                                        fontSize: '5px',padding: '3px',
                                        height: '10px',
                                        width: '10px',
                                        position: 'relative',
                                        top: '1px',
                                        left: '-5px',
                                        backgroundColor: 'red',
                                        color: '#fff',
                                    }}>{unreadNotification}</sup>}
                                </i>
                            </a>
                            <ul style={{overflowY: 'scroll', maxHeight: '300px'}} className="dropdown-menu res-dropdown-menu dropdown-menu-end border-0 shadow px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                                {notifications ? notifications.map((notification) => (
                                    <li className="mb-2" onClick={() => seenNotification()}>
                                        <NavLink className="dropdown-item border-radius-md" to={'/project-management/project/edit/' + notification.task_id}>
                                            <div className="d-flex py-1">
                                                <div className="my-auto">
                                                   
                                                </div>
                                                <div className="d-flex flex-column justify-content-center">
                                                    <h6 className="text-sm font-weight-normal mb-1">{notification.title}</h6>
                                                    <p className="text-xs text-secondary mb-0">
                                                        <i className="fa fa-clock me-1" />
                                                        <Moment fromNow>{notification.created_at}</Moment>
                                                    </p>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li> 
                                )): <></>}
                            </ul>
                        </li>
                        <li className="nav-item d-flex align-items-center"> 
                            <div className="ms-md-auto pe-md-3 d-flex">
                                <div className="dropdown">
                                    <a href="flags" className="m-0 nav-link text-body font-weight-bold px-0" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg" alt="" style={{ height: "25px", width: "25px" }}/>
                                    </a>
                                    <ul className="dropdown-menu res-dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby="dropdownMenuButton1">
                                        <div style={{padding: "0px 15px"}}>
                                        <li className="">{user && user.name ? user.name : '' }</li>
                                        <li className="">{user && user.email ? user.email : '' }</li>
                                        </div>
                                        <div class="dropdown-divider"></div>
                                        <li><a href="#0" className="dropdown-item">{t('Dashboard')}</a></li>
                                        {Cookies.get('user') && JSON.parse(Cookies.get('user')).rolename == 'Call Center' || JSON.parse(Cookies.get('user')).rolename == 'Technical Team' ? (
                                            <>

                                            </>
                                        ) : <>
                                                <li><NavLink to="/profile/settings" className="dropdown-item">{t('Settings')}</NavLink></li>
                                                                                  
                                        </>}
                                        <li><a href="#0" onClick={(e) => doSignout(e)} className="dropdown-item">{t('Sign Out')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default TopNav;
