import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import dateFormat from "dateformat";
import flatpickr from "flatpickr";
import Cookies from 'js-cookie';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftDropzone from "components/SoftDropzone";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { NumericFormat } from 'react-number-format';

function Edit() {
    let params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [quationId, setQuationId] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    const [quation, setQuation] = useState({
        gesami_netto: 0,
        gesami_brutto: 0,
    });
    const [contactPersons, setContactPersons] = useState([]);
    const [data, setData] = useState([]);
    const [emails, setEmails] = useState([{ name: '', email: '', currentEmail: '' }]);
    const [items, setItems] = useState([{ id: 0, title: '', quantity: 0, price: 0, currentPrice: 0, total: 0 }]);
    const [itemSummery, setItemSummery] = useState({ items: [], emails: [], subTotal: 0, discountPercentage: 0, discount: 0, taxPercentage: 20, tax: 0, total: 0 });
    const [order, setOrder] = useState(0);
    const [saving, setSaving] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [currentAttachmentTab, setCurrentAttachmentTab] = useState('dachansicht');

    const [attachments, setAttachment] = useState([]);
    const [dachansicht, setDachansicht] = useState([]);
    const [dachsparren, setDachsparren] = useState([]);
    const [dachgiebel, setDachgiebel] = useState([]);
    const [gesamtansicht_von_haus_und_dach, setGesamtansicht_von_haus_und_dach] = useState([]);
    const [innendachansicht, setInnendachansicht] = useState([]);
    const [kompletter_zahlerschrank, setKompletter_zahlerschrank] = useState([]);
    const [zahler, setZahler] = useState([]);
    const [wechselrichter_standort, setWechselrichter_standort] = useState([]);
    const [bei_nachust, setBei_nachust] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();

    const handeleAttachment = (name, newData) => {
        clearErrors(name);

        if(name == 'dachansicht'){
            let data = dachansicht;
            data[dachansicht.length] = newData;
            setDachansicht(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'dachsparren'){
            let data = dachsparren;
            data[dachsparren.length] = newData;
            setDachsparren(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'dachgiebel'){
            let data = dachgiebel;
            data[dachgiebel.length] = newData;
            setDachgiebel(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'gesamtansicht_von_haus_und_dach'){
            let data = gesamtansicht_von_haus_und_dach;
            data[gesamtansicht_von_haus_und_dach.length] = newData;
            setGesamtansicht_von_haus_und_dach(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'innendachansicht'){
            let data = innendachansicht;
            data[innendachansicht.length] = newData;
            setInnendachansicht(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'kompletter_zahlerschrank'){
            let data = kompletter_zahlerschrank;
            data[kompletter_zahlerschrank.length] = newData;
            setKompletter_zahlerschrank(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'zahler'){
            let data = zahler;
            data[zahler.length] = newData;
            setZahler(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'wechselrichter_standort'){
            let data = wechselrichter_standort;
            data[wechselrichter_standort.length] = newData;
            setWechselrichter_standort(data);
            setValue(name, JSON.stringify(data));
        }else if(name == 'bei_nachust'){
            let data = bei_nachust;
            data[bei_nachust.length] = newData;
            setBei_nachust(data);
            setValue(name, JSON.stringify(data));
        }
    }

    const removeAttachment = (id) => {
        const beforeRemove = eval(currentAttachmentTab);
        const afterRemove = beforeRemove.filter(value => {
            return value.id != id;
        });
        if(currentAttachmentTab == 'dachansicht'){ setDachansicht(afterRemove);
        }else if(currentAttachmentTab == 'dachsparren'){  setDachsparren(afterRemove);
        }else if(currentAttachmentTab == 'dachgiebel'){ setDachgiebel(afterRemove);
        }else if(currentAttachmentTab == 'gesamtansicht_von_haus_und_dach'){ setGesamtansicht_von_haus_und_dach(afterRemove);
        }else if(currentAttachmentTab == 'innendachansicht'){ setInnendachansicht(afterRemove);
        }else if(currentAttachmentTab == 'kompletter_zahlerschrank'){ setKompletter_zahlerschrank(afterRemove);
        }else if(currentAttachmentTab == 'zahler'){ setZahler(afterRemove);
        }else if(currentAttachmentTab == 'wechselrichter_standort'){ setWechselrichter_standort(afterRemove);
        }else if(currentAttachmentTab == 'bei_nachust'){ setBei_nachust(afterRemove);
        }        
        setValue(currentAttachmentTab, JSON.stringify(afterRemove));
    }

    useEffect(() => {
        var subTotal = 0;
        items.map((item) => {
            subTotal += item.total;
        });
        itemSummery.subTotal = subTotal;
        itemSummery.tax = parseFloat((subTotal * (itemSummery.taxPercentage / 100)).toFixed(2));
        itemSummery.discount = parseFloat((subTotal * (itemSummery.discountPercentage / 100)).toFixed(2));
        itemSummery.total = subTotal - itemSummery.discount + itemSummery.tax;
        itemSummery.items = items;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }, [refresh]);

    useEffect(() => { }, [refresh2]);

    useEffect(() => {
        if (quationId < 1)
            return;

        callFetch("quation/" + quationId + "/edit", "GET", []).then((res) => {
            setQuation(res.data);
            setValue('netto', Number(res.data.gesami_netto));
            setValue('brutto', Number(res.data.gesami_brutto));
            //setContactPersons(res.data);
            //setEmails([{ name: '', email: '', currentEmail: '' }]);
            //itemSummery.emails = [];
            //setItemSummery(itemSummery);
            setRefresh2(refresh2 + 1);
        });

    }, [quationId]);

    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("order/" + params.id + "/edit", "GET", []).then((res) => {
            if(res.message != 'success'){
                return  navigate('/not-found');
            }
            
            setData(res.data);
            
            for (let [key, value] of Object.entries(res.data.order)) {
                if(key == 'order_date'){
                    setValue(key, dateFormat(value, "yyyy-mm-dd"));
                }else if(key == 'netto' || key == 'brutto'){
                    setValue(key, Number(value));
                }else{
                    setValue(key, value);
                }
            }
            setValue('status', 1);
            
            setDachansicht(res.data.order.dachansicht  && res.data.order.dachansicht != null && res.data.order.dachansicht != 'null' ? JSON.parse(res.data.order.dachansicht) : null);
            setDachsparren(res.data.order.dachsparren  && res.data.order.dachsparren != null && res.data.order.dachsparren != 'null' ? JSON.parse(res.data.order.dachsparren) : null);
            setDachgiebel(res.data.order.dachgiebel  && res.data.order.dachgiebel != null && res.data.order.dachgiebel != 'null' ? JSON.parse(res.data.order.dachgiebel) : null);
            setGesamtansicht_von_haus_und_dach(res.data.order.gesamtansicht_von_haus_und_dach  && res.data.order.gesamtansicht_von_haus_und_dach != null && res.data.order.gesamtansicht_von_haus_und_dach != 'null' ? JSON.parse(res.data.order.gesamtansicht_von_haus_und_dach) : null);
            setInnendachansicht(res.data.order.innendachansicht  && res.data.order.innendachansicht != null && res.data.order.innendachansicht != 'null' ? JSON.parse(res.data.order.innendachansicht) : null);
            setKompletter_zahlerschrank(res.data.order.kompletter_zahlerschrank  && res.data.order.kompletter_zahlerschrank != null && res.data.order.kompletter_zahlerschrank != 'null' ? JSON.parse(res.data.order.kompletter_zahlerschrank) : null);
            setZahler(res.data.order.zahler  && res.data.order.zahler != null && res.data.order.zahler != 'null' ? JSON.parse(res.data.order.zahler) : null);
            setWechselrichter_standort(res.data.order.wechselrichter_standort  && res.data.order.wechselrichter_standort != null && res.data.order.wechselrichter_standort != 'null' ? JSON.parse(res.data.order.wechselrichter_standort) : null);
            setBei_nachust(res.data.order.bei_nachust  && res.data.order.bei_nachust != null && res.data.order.bei_nachust != 'null' ? JSON.parse(res.data.order.bei_nachust) : null);

            let pd = res.data.order.product_details  && res.data.order.product_details != null && res.data.order.product_details != 'null' ? JSON.parse(res.data.order.product_details) : [];
            //let cd = res.data.order.contact_person  && res.data.order.contact_person != null && res.data.order.contact_person != 'null' ? JSON.parse(res.data.order.customer.contact_person) : [];
            //setContactPersons(cd);
            setQuationId(res.data.order.quation_id);
            //setEmails(pd.emails);
            setItems(pd.items);
            setItemSummery(pd);
        });
    }, []);

    function deleteProduct() {
        var titems = [];
        items.map((t) => {
            if (!t)
                return;
            titems.push(t);
        });
        setItems(titems);
        setRefresh(refresh + 1);
    }

    function deleteEmail() {
        var temails = [];
        emails.map((e) => {
            if (!e && e !== '')
                return;
            temails.push(e);
        });
        setEmails(temails);
        itemSummery.emails = temails;
        setItemSummery(itemSummery);
        setRefresh2(refresh2 + 1);
    }

    const fileUploadTab = (name) => {
        console.log(name);
        setCurrentAttachmentTab(name);
    }

    const dropZoneInitialize = () => {
        return(
            <SoftDropzone
                options={{
                    dictDefaultMessage: t('Bilder Hochladen'),
                   // addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf,.doc,.docx",
                    action: process.env.REACT_APP_API_URL + 'attachment',
                    headers:{
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if(response.message == 'success'){
                            handeleAttachment(currentAttachmentTab, response.data);
                        }
                    },
                    error: function(file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    const onSubmit = (formData) => { 
        if(getValues('dachansicht') != 'null' || getValues('dachsparren') != 'null' || getValues('dachgiebel') != 'null' || getValues('gesamtansicht_von_haus_und_dach') != 'null' || getValues('innendachansicht') != 'null' || getValues('kompletter_zahlerschrank') != 'null' || getValues('zahler') != 'null' || getValues('wechselrichter_standort') != 'null' || getValues('bei_nachust') != 'null'){
            console.log(getValues('dachansicht'));
            console.log(getValues('dachsparren'));
            console.log(getValues('dachgiebel'));
            console.log(getValues('gesamtansicht_von_haus_und_dach'));
            console.log(getValues('innendachansicht'));
            console.log(getValues('kompletter_zahlerschrank'));
            console.log(getValues('zahler'));
            console.log(getValues('wechselrichter_standort'));
            console.log(getValues('bei_nachust'));
            setSaving(true);
            formData.total = 0;
            formData.product_details = JSON.stringify(itemSummery);
            callFetch("order/" + params.id, "POST", formData, setError).then((res) => {
                setSaving(false);
                if (!res.ok) return;
                //setSubmitSuccess(true);
            });
        } 
        else if(getValues('dachansicht') != '[]' || getValues('dachsparren') != '[]' || getValues('dachgiebel') != '[]' || getValues('gesamtansicht_von_haus_und_dach') != '[]' || getValues('innendachansicht') != '[]' || getValues('kompletter_zahlerschrank') != '[]' || getValues('zahler') != '[]' || getValues('wechselrichter_standort') != '[]' || getValues('bei_nachust') != '[]'){
          
        }else{
            alert('You must upload an file');
        }
    };

    return submitSuccess ? <Navigate to='/sales-distribution/orders' /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('New Order')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />
                            <input type="hidden" {...register("status")} />

                            <div className="row g-3">
                                <div className="col-md-3">
                                    <label>{t('Order Nr.')} *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('eg: 400001')}
                                        {...register("id", {
                                            required: true,
                                        })}
                                        readOnly
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.id && errors.id.message}</div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Angebot')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            value={quationId}
                                            {...register("quation_id", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setQuationId(e.target.value);
                                            }}
                                            required
                                        >
                                            <option value="">--</option>
                                            { data.angebots && data.angebots.map((angebot) => (
                                                <option key={angebot.id} value={angebot.id}>{angebot.id}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">{errors.quation_id && errors.quation_id.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>
                                            {t('Currency')} *
                                        </label>
                                        <br />
                                        <select
                                            className="form-control"
                                            {...register("currency", {
                                                required: true,
                                            })}
                                            onChange={(e) => {
                                                setCurrency(e.target.value);
                                            }}
                                            required
                                        >
                                            <option value="EUR">EUR (€)</option>
                                            <option value="USD">USD ($)</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.currency && errors.currency.message}</div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label>
                                        {t('Order Date')} *
                                    </label>
                                    <input
                                        type="date"
                                        className="form-control mb-4 flat-pickr"
                                        placeholder={t('eg. 2001-03-20')}
                                        defaultValue={dateFormat(new Date(), "yyyy-mm-dd")}
                                        {...register("order_date", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.order_date && errors.order_date.message}</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <ul className="order_upload_tabs" id="order_upload_tabs">
                                        <li className={currentAttachmentTab == 'dachansicht' ? 'active' : ''} onClick={()=>{fileUploadTab('dachansicht')}} >{t('Dachansicht')}</li>
                                        <li className={currentAttachmentTab == 'dachsparren' ? 'active' : ''} onClick={()=>{fileUploadTab('dachsparren')}} >{t('Dachsparren')}</li>
                                        <li className={currentAttachmentTab == 'dachgiebel' ? 'active' : ''} onClick={()=>{fileUploadTab('dachgiebel')}} >{t('Dachgiebel')}</li>
                                        <li className={currentAttachmentTab == 'gesamtansicht_von_haus_und_dach' ? 'active' : ''} onClick={()=>{fileUploadTab('gesamtansicht_von_haus_und_dach')}} >{t('Gesamtansicht von Haus und Dach')}</li>
                                        <li className={currentAttachmentTab == 'innendachansicht' ? 'active' : ''} onClick={()=>{fileUploadTab('innendachansicht')}} >{t('Innendachansicht')}</li>
                                        <li className={currentAttachmentTab == 'kompletter_zahlerschrank' ? 'active' : ''} onClick={()=>{fileUploadTab('kompletter_zahlerschrank')}} >{t('Kompletter Zählerschrank')}</li>
                                        <li className={currentAttachmentTab == 'zahler' ? 'active' : ''} onClick={()=>{fileUploadTab('zahler')}} >{t('Zähler')}</li>
                                        <li className={currentAttachmentTab == 'wechselrichter_standort' ? 'active' : ''} onClick={()=>{fileUploadTab('wechselrichter_standort')}} >{t('Wechselrichter-Standort')}</li>
                                        <li className={currentAttachmentTab == 'bei_nachust' ? 'active' : ''} onClick={()=>{fileUploadTab('bei_nachust')}} >{t('Router (bei Nachust')}</li>
                                    </ul>
                                    {errors.dachansicht && errors.dachansicht.message ? (
                                        <div className="invalid-feedback d-block">{t('The dachansicht is required.')}</div>
                                    ) : errors.dachsparren && errors.dachsparren.message ? (
                                        <div className="invalid-feedback d-block">{t('The dachsparren is required.')}</div>
                                    ) : errors.dachgiebel && errors.dachgiebel.message ? (
                                        <div className="invalid-feedback d-block">{t('The dachgiebel is required.')}</div>
                                    ) : errors.gesamtansicht_von_haus_und_dach && errors.gesamtansicht_von_haus_und_dach.message ? (
                                        <div className="invalid-feedback d-block">{t('The Gesamtansicht von Haus und Dach is required.')}</div>
                                    ) : errors.innendachansicht && errors.innendachansicht.message ? (
                                        <div className="invalid-feedback d-block">{t('The innendachansicht is required.')}</div>
                                    ) : errors.kompletter_zahlerschrank && errors.kompletter_zahlerschrank.message ? (
                                        <div className="invalid-feedback d-block">{t('The Kompletter Zählerschrank is required.')}</div>
                                    ) : errors.zahler && errors.zahler.message ? (
                                        <div className="invalid-feedback d-block">{t('The zahler is required.')}</div>
                                    ) : errors.wechselrichter_standort && errors.wechselrichter_standort.message ? (
                                        <div className="invalid-feedback d-block">{t('The Wechselrichter-Standort is required.')}</div>
                                    ) : errors.bei_nachust && errors.bei_nachust.message ? (
                                        <div className="invalid-feedback d-block">{t('The Router (bei Nachust) is required.')}</div>
                                    ) : ''}
                                </div>
                                <div className="col-md-8">
                                    <SoftBox>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            height="100%"
                                            >

                                            {dropZoneInitialize()}
                                            
                                        </SoftBox>
                                    </SoftBox>

                                    <SoftBox p={3} className="order-processing">
                                        <Grid container spacing={3}>
                                            {eval(currentAttachmentTab) ? eval(currentAttachmentTab).map(function(data, i){
                                                return (
                                                    <Grid item key={i} className="text-center">
                                                        <div>
                                                            <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+data.attachment}>
                                                                <img src={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+data.attachment} />
                                                            </a>
                                                        </div>
                                                        <a onClick={() => { removeAttachment(data.id) }}>{t('Remove')}</a>
                                                    </Grid>
                                                );
                                            }) : <><Grid></Grid></>}
                                        </Grid>
                                    </SoftBox>
                                </div>
                            </div>
                            

                            <div className="mt-3">
                            <hr />

                                <div className="row">
                                    <div className="col-md-6 offset-md-6 border">
                                        <div className="row">
                                            <div className="col-md-7 border-end">
                                                <label className="d-block text-end">{t('Netto')}</label>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat 
                                                        value={getValues('netto')}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        
                                        <div className="row border-top">
                                            <div className="col-md-3 border-end">
                                                <label className="d-block text-end">{t('Tax')}</label>
                                            </div>
                                            <div className="col-md-3 border-end">
                                                <input type="number"
                                                    {...register("tax", {
                                                        required: true,
                                                    })}
                                                    className="form-control"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-md-1 border-end">
                                                <p className="text-end">%</p>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat 
                                                        value={(getValues('brutto')-getValues('netto'))} 
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row bg-gray-200">
                                            <div className="col-md-7">
                                                <label className="d-block text-end">{t('Brutto')}</label>
                                            </div>
                                            <div className="col-md-5">
                                                <p className="text-end">
                                                    <NumericFormat 
                                                        value={getValues('brutto')}
                                                        displayType="text" 
                                                        thousandSeparator={"."} 
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        suffix={currency == 'EUR' ? ' € ' : ' $ '}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {emails && emails.map((email, i) => (
                                <div key={i} className="row mt-3">
                                    {/*<div className="col-md-4">
                                        <label>{t('Name')}</label>
                                        <select className="form-control" value={email.name + '*' + email.email} onChange={(e) => {
                                            var cp = e.target.value.split('*');
                                            emails[i].name = cp[0];
                                            emails[i].email = cp[1];
                                            emails[i].currentEmail = cp[1];
                                            setEmails(emails);
                                            itemSummery.emails = emails;
                                            setItemSummery(itemSummery);
                                            setRefresh2(refresh2 + 1);
                                        }}>
                                            <option value="">--</option>
                                           
                                        </select>
                                    </div>*/}

                                    <div className="col-md-4">
                                        <label>{t('Name')}</label>
                                        <input
                                            type="text" 
                                            className="form-control d-inline-block mt-1" 
                                            value={email.name} onChange={(e) => {
                                            emails[i].name = e.target.value;
                                            setEmails(emails);
                                            itemSummery.emails = emails;
                                            setItemSummery(itemSummery);
                                            setRefresh2(refresh2 + 1);
                                        }}
                                        placeholder="Name"
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <label>{t('Email')}</label>
                                        <input 
                                            type="email" 
                                            style={{ width: '93%' }} 
                                            className="form-control d-inline-block mt-1" 
                                            value={email.email} onChange={(e) => {
                                            emails[i].email = e.target.value;
                                            setEmails(emails);
                                            itemSummery.emails = emails;
                                            setItemSummery(itemSummery);
                                            setRefresh2(refresh2 + 1);
                                        }}
                                        placeholder="eg. email@mail.com"
                                        />
                                        &nbsp;
                                        <i className="fa-solid fa-circle-xmark text-danger" data-key={i} onClick={() => { delete emails[i]; deleteEmail(); }}></i>

                                        <br />
                                    </div>
                                </div>
                            ))}
                            <p className="btn btn-dark mt-2" style={{ cursor: 'pointer' }} onClick={() => { setEmails([...emails, { name: '', email: '', currentEmail: '' }]); setRefresh2(refresh2 + 1); }}><i className="fa-solid fa-circle-plus"></i>&nbsp;{t('Add Contact Person')}</p>

                            <div className="row mt-3">
                                <div className="col-md-12">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}

                                    <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'order/'+  params.id}  className="btn btn-primary ms-2" >{t('Order PDF')}</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    ;
}

export default Edit;