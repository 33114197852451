import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
function Sidebar() {
    const { t } = useTranslation();
    const [lastMessage, setLastMessage] = useState([]);
    const [authId, setAuthId] = useState('');
    const [nowDrop, setNowDrop] = useState(window.location.href.split('/')[3]);
    let navClassName = "nav-link";
    let activeClassName = "nav-link active";
    let dropdownClass = "collapse";
    let dropdownClassShow = "collapse show";

    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                <a className="navbar-brand m-0" href="/">
                    <img src="/assets/img/logo.png" className="navbar-brand-img h-100" alt="main_logo" />
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    
                    <li className="nav-item">
                        <NavLink to="/dashboard" onClick={(e) => setNowDrop('dashboard')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Dashboard')}</span>
                        </NavLink>
                    </li>

                    {Cookies.get('permissions').indexOf("project-read") !== -1 || Cookies.get('permissions').indexOf("project-create") !== -1  ? (
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#project-management" className={nowDrop === 'project-management' ? activeClassName : navClassName} aria-controls="project-management" role="button" aria-expanded={nowDrop === 'project-management'}>
                            <i className="fa-solid fa-briefcase icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Project Management')}</span>
                        </a>
                        <div className={nowDrop === 'project-management' ? dropdownClassShow : dropdownClass} id="project-management">
                            <ul className="nav ms-4 ps-3">
                            {Cookies.get('permissions').indexOf("project-create") !== -1 ? (
                                <li className="nav-item">
                                    <NavLink to="/project-management/project/create" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                        <span className="sidenav-normal">{t('New Project')}</span>
                                    </NavLink>
                                </li>
                            ) : <></>}
                            {Cookies.get('permissions').indexOf("project-read") !== -1 ? (
                                <li className="nav-item">
                                    <NavLink to="/project-management/projects" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                        <span className="sidenav-normal">{t('Projects')}</span>
                                    </NavLink>
                                </li>
                            ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                    <li className={"nav-item"}>
                        <a data-bs-toggle="collapse" href="#hr" className={nowDrop === 'hr' ? activeClassName : navClassName} aria-controls="hr" role="button" aria-expanded={nowDrop === 'hr'}>
                            <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Staff Management')}</span>
                        </a>
                        <div className={nowDrop === 'hr' ? dropdownClassShow : dropdownClass} id="hr">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/hr/employees" onClick={(e) => setNowDrop('hr')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Employees')}</span>
                                            </NavLink>
                                        </li>                                    
                                    </>
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#supply-chain" className={nowDrop === 'supply-chain' ? activeClassName : navClassName} aria-controls="supply-chain" role="button" aria-expanded={nowDrop === 'supply-chain'}>
                                <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Supply Chain')}</span>
                            </a>
                            <div className={nowDrop === 'supply-chain' ? dropdownClassShow : dropdownClass} id="supply-chain">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                                        <>
                                            {/*<li className="nav-item">
                                                <NavLink to="/supply-chain/product" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Products')}</span>
                                                </NavLink>
                                            </li>*/}

                                            <li class="nav-item ">
                                                <a class="nav-link collapsed" data-bs-toggle="collapse" aria-expanded="false" href="#productsExample">
                                                    <span class="sidenav-mini-icon"> P </span>
                                                    <span class="sidenav-normal"> {t('Products')} <b class="caret"></b></span>
                                                </a>
                                                <div class="collapse" id="productsExample">
                                                    <ul class="nav nav-sm flex-column">
                                                        {Cookies.get('permissions').indexOf("product-create") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/supply-chain/products/create" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('New Product')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}

                                                        {Cookies.get('permissions').indexOf("module-read") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/supply-chain/products/module" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('Module')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}

                                                        {Cookies.get('permissions').indexOf("speicher-read") !== -1 ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <NavLink to="/supply-chain/products/speicher" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                        <span className="sidenav-normal">{t('Speicher')}</span>
                                                                    </NavLink>
                                                                </li>
                                                            </>
                                                        ) : <></>}
                                                    </ul>
                                                </div>
                                            </li>
                                        </>
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                        ) : <></>}
                                        
                    <li className="nav-item d-none">
                        <NavLink to="/application-settings" onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa fa-globe icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                            <span className="nav-link-text ms-1">Application Settings</span>
                        </NavLink>
                    </li>

                    
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
