import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import dateFormat, { masks } from "dateformat";
import { NumericFormat } from 'react-number-format';

import callFetch from "../../../helpers/callFetch";

function Timeline(props) {
    const { t } = useTranslation();
    let params = useParams();

    const [data, setData] = useState([]);

    useEffect(() => {
        if(params.id){
            callFetch("project/timelines/"+params.id, "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }, [params.id+(props.refresh ? props.refresh : 0)]);

    return(
        <>
            {data && data.project ? (
                <div className="card">
                    <div className="card-body">
                        <h6>{t('Timeline')}</h6>
                        <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">
                            <div className="timeline-block mb-3">
                                <span className="timeline-step">
                                    <i className="ni ni-bell-55 text-success text-gradient" />
                                </span>
                                <div className="timeline-content">
                                    <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Appointment')}</h6>
                                    <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{dateFormat(data.project.date, "dd.mm.yyyy")}</p>
                                    <p className="text-sm mt-3 mb-2">{t('Client Information')}:</p>
                                    <p className="text-sm m-0">{t('Name')}: {data.project && data.project != undefined ? data.project.vorname+' '+data.project.name : ''}</p>
                                    <p className="text-sm m-0">{t('Straße')}: {data.project && data.project != undefined ? data.project.street : ''}</p>
                                    <p className="text-sm m-0">{t('PLZ und Ort')}: {data.project && data.project != undefined ? data.project.plz+' '+data.project.ort : ''}</p>
                                    <p className="text-sm m-0">{t('Phone')}: {data.project && data.project != undefined ? data.project.telefonnummer : ''}</p>
                                </div>
                            </div>

                            {data.quations && data.quations.map((quation) => (
                                <>
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Quotation')}</h6>
                                        <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(quation.updated_at, "dd.mm.yyyy")}</p>
                                        <p className="text-sm m-0">{t('Quotation Nr.')}: {quation.id}</p>
                                        <p className="text-sm m-0">{t('Price')} Netto: <NumericFormat value={quation.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€</p>
                                    </div>
                                </div>                                
                                </>
                            ))}

                            {data.orders && data.orders.map((order) => (
                                <>
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Order')}</h6>
                                        <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(order.order_date, "dd.mm.yyyy")}</p>
                                        <p className="text-sm m-0">{t('Order Nr.')}: {order.id}</p>
                                        <p className="text-sm m-0">{t('Price')} Netto: <NumericFormat value={order.netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€</p>
                                    </div>
                                </div>                                
                                </>
                            ))}
                            
                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                        <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                        <p className="text-secondary font-weight-bold text-xs m-0">{assign_order.time}</p>
                                        <p className="text-sm mt-1">{t('Employee')}: {assign_order.name}</p> 
                                    </div>
                                </div>                                
                                </>
                            ))}

                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default Timeline;
