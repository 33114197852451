import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Navigate } from "react-router-dom";

import { callFetch } from "../../helpers/callFetch";

import IndexTable from './IndexTable';

function EmployeeIndex() {
    const { t } = useTranslation();

    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [orderId, setOrderId] = useState(0);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();


    const create = () => {
        setSaving(true);
        callFetch("order", "POST", {
            quation_id: 0,
            currency: 'EUR',
            netto: 0,
            tax: 19,
            brutto: 0,
        }, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setOrderId(res.data.id);
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/sales-distribution/order/' + orderId + '/edit'} /> :
        <>
            <div className="d-sm-flex justify-content-between">
            <div className="relative-h">
                {Cookies.get('permissions').indexOf("order-create") !== -1 ? (
                    <button onClick={()=>create()} className="btn btn-icon btn-primary">
                        {t('Create Order')}
                    </button>
                ) : <></>}
                
                {Cookies.get('permissions').indexOf("order-create") !== -1 ? (
                     <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'order-export'} className="btn btn-outline-dark pull-right">
                        {t('Export CSV')}
                    </a>
                ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Orders')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>;
}

export default EmployeeIndex;
