import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function Create() {
    const { t } = useTranslation();
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [product_type, setProduct_type] = useState('module');
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const Product_type = (e) => {
        const value = e.target.value;
        setProduct_type(value);
    }

    useEffect(() => {
        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res.data);
        });
    }, [refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch(product_type, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to={'/supply-chain/products/'+product_type} /> :
        <div className="row">
            <div className="col-md-8">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Create Product')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">

                            <div className="col-md-12">
                                    <label>{t('Product Type')} *</label>
                                    <select className="form-control" name="product_type" onChange={Product_type} required>
                                        <option value="module">{t('Module')}</option>
                                        <option value="speicher">{t('Speicher')}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.product_type && errors.product_type.message}</div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Title')} *</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                                
                                {product_type == 'module' ? (
                                    <>
                                    <div className="col-md-12">
                                        <label>{t('Short Title')} *</label>
                                        <input
                                            type="text"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("short_itle", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('Watt')} *</label>
                                        <input
                                            type="number"
                                            className="form-control mb-4"
                                            placeholder={t('')}
                                            {...register("watt", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                    </div>                                    
                                    </>
                                ): <></>}

                                <div className="col-md-12">
                                    <label>{t('Description')}</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('')}
                                        {...register("description")}></textarea>
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>    
                                
                                {product_type == 'speicher' ? (
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <label>{t('Ohne Energiespeicher')}</label>
                                        <input className="form-check-input" type="checkbox" value={"Yes"} {...register("without_energiespeichersystem")} />
                                    </div>
                                    <div className="invalid-feedback">{errors.without_energiespeichersystem && errors.without_energiespeichersystem.message}</div>
                                </div>    
                                ): <></>}                        
                                
                                <div className="col-12 mb-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default Create;