import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import EmployeeIndexTable from "./EmployeeIndexTable";
import Cookies from 'js-cookie';

function EmployeeIndex() {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                {Cookies.get('permissions').indexOf("employee-create") !== -1 ? (
                    <NavLink to="/hr/employees/create" className="btn btn-icon btn-primary">
                        {t('Add Employee')}
                    </NavLink>
                ) : <></>}

                {Cookies.get('permissions').indexOf("employee-create") !== -1 ? (
                    <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'employee-export'} className="btn btn-outline-dark pull-right">
                        {t('Export CSV')}
                    </a>
                ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Employees')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <EmployeeIndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeIndex;
